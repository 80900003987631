body {
  font-family: 'Open Sans', sans-serif;
  margin: 0px;
  font-size: 16px;
  color: #252525;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}

p {
  margin: 0px;
}
.background {
  background-blend-mode: soft-light;
  background-color: #ebebeb;
  width: 100%;
  height: 100vh;
  position: fixed;
  /* background-image: -moz-linear-gradient( 45deg, rgb(137,137,137) 1%, rgb(198,198,193) 36%, rgb(198,198,193) 42%, rgb(167,176,185) 60%, rgb(105,109,135) 69%, rgb(105,109,135) 77%, rgb(27,15,33) 98%) !important;
  background-image: -webkit-linear-gradient( 45deg, rgb(137,137,137) 1%, rgb(198,198,193) 36%, rgb(198,198,193) 42%, rgb(167,176,185) 60%, rgb(105,109,135) 69%, rgb(105,109,135) 77%, rgb(27,15,33) 98%) !important;
  background-image: -ms-linear-gradient( 45deg, rgb(137,137,137) 1%, rgb(198,198,193) 36%, rgb(198,198,193) 42%, rgb(167,176,185) 60%, rgb(105,109,135) 69%, rgb(105,109,135) 77%, rgb(27,15,33) 98%) !important; */

  background-image: conic-gradient(
    from 45deg,
    #898989 1%,
    #c6c6c1 36%,
    #c6c6c1 42%,
    #a7b0b9 60%,
    #696d87 69%,
    #696d87 77%,
    #1b0f21 98%
  );
  /* background-image:url("/assets/bGImage.svg"),linear-gradient(274.28deg, rgba(212, 210, 213, 0.6) 16.75%, rgba(255, 255, 255, 0) 106.12%); */
}

.premium {
  background: #d6cb3f;
  position: absolute;
  border-radius: 12px;
  padding: 1px 4px;
  font-size: 10px;
  right: 20px;
  top: -10px;
  cursor: pointer;
}
.blue-text {
  color: #1e4bb4 !important;
}
.add-scenario {
  background: #d6cb3f;
  color: rgb(19, 53, 22);
  font-size: 16px;
  padding: 20px 15px;
  cursor: pointer;
}
.close-icon {
  font-size: 23px;
  color: red;
  margin: 20px;
}
.main-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  padding-bottom: 400px;
}
.view-scenarios {
  color: black;
  font-size: 30px;
  cursor: pointer;
}
.permium-row {
  display: flex;
  flex-direction: row;
  justify-content: start;
}
.premium-row-text {
  background: #d6cb3f;
  border-radius: 12px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 1px 4px;
  font-size: 10px;
  cursor: pointer;
}
.permium-text {
  color: #c4b702;
}
.highlight {
  background-color: #cccccc !important;
}

.unit {
  height: 100vh;
}

.row {
  margin: 0px;
}

.bold {
  font-weight: bold;
}

.navbar-brand {
  margin-left: 36px;
}

.nav-link {
  font-weight: bold;
  font-size: 14px;
  color: #b7b7b7 !important;
}

.nav-link:hover {
  color: #a09e9e !important;
}

.navbar {
  height: 80px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.scrolled {
  position: fixed;
  height: 80px;
  width: 100%;
  background-color: #ffffff;
  z-index: 2;
}

.coverimage {
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  background-position: center;
}

.image1 {
  background-image: url('/assets/bg-1.png');
}

.image2 {
  background-image: url('/assets/bg-2.png');
}

.image3 {
  background-image: url('/assets/bg-3.png');
}
.image4 {
  background-image: url('/assets/bg-4.png');
}

/* .fill{
  height: calc(100% - 80px);
} */

.top-border thead th {
  border-top: 2px solid #363636;
}

.single-row {
  margin-top: 10px;
  margin-bottom: 50px;
}

.single-row thead th {
  border-top: 2px solid #363636;
  border-bottom: 0px !important;
  font-weight: 600;
  color: #252525;
  text-align: center;
}

.single-row tbody tr td {
  font-size: 14.5px;
  text-align: center;
  padding-top: 0px;
  border-bottom: 2px solid #363636;
}

#report-body {
  padding-top: 30px;
  padding-bottom: 30px;
}

.disclaimer {
  font-size: 14px !important;
}

.login-container {
  padding: 140px;
  padding-top: 80px;
  padding-bottom: 80px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
}

.login-text {
  color: #363636;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 14px;
  margin-top: 35px;
}
.box-wrapper {
  display: flex;
  flex-direction: column;
  width: 83%;
}
.signup-text {
  color: #363636;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 14px;
  margin-top: 25px;
}
.login-input {
  color: #363636;
  font-size: 16px;
}
.wrapperStyles {
  width: 100%;
  max-width: 1400px !important;
  margin: 0 auto;
  position: static;
}

.manu-dropdown {
  padding: 0px !important;
  min-width: -webkit-fit-content !important;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
  left: auto;
  right: 33px;
  border: 0.5px solid #b7b7b7 !important;
  border-radius: 0px !important;
  top: 52px;
}
.item-dropdown {
  background-color: rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-left: 10px;
  padding-top: 5px !important;
  padding-right: 10px !important;
  font-weight: bold;
  font-size: 13px;
  color: #b7b7b7 !important;
  word-spacing: 3px;
}
.item-padding {
  cursor: pointer;
  padding-bottom: 5px !important;
}
.text-box1 {
  background-color: rgba(0, 0, 0, 0.1);
  width: 300px;
  height: 54px;
  padding: 20px;
  display: flex;
  align-items: center;
}

.freemium-box {
  width: 300px;
  height: 54px;
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
}

.batch-info {
  position: absolute;
  top: 7px;
  right: 16px;
}

.close-popup {
  margin-left: 19rem !important;
}

.button1 {
  width: 196px;
  height: 53px;
  background-color: #252525;
  font-size: 16px;
  color: white !important;
  font-weight: 300;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  transition: box-shadow 500ms;
  cursor: pointer;
  margin-top: 35px;
}
.logout-btn {
  width: 70px;
  height: 30px;
  background-color: #252525;
  font-size: 12px;
  color: white !important;
  font-weight: 300;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  transition: box-shadow 500ms;
  cursor: pointer;
}
.logout-btn:hover {
  box-shadow: 0px 8px 24px 0 rgba(0, 0, 0, 0.3);
}
.button1:hover {
  box-shadow: 0px 8px 24px 0 rgba(0, 0, 0, 0.3);
}

.button2:hover {
  box-shadow: 0px 8px 24px 0 rgba(0, 0, 0, 0.3);
}

.button3:hover {
  box-shadow: 0px 8px 24px 0 rgba(0, 0, 0, 0.3);
}

.button3 img {
  margin-right: 10px;
}

.button2 {
  width: 240px;
  height: 53px;
  background-color: #252525;
  font-size: 16px;
  color: white !important;
  font-weight: 300;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  transition: box-shadow 500ms;
  cursor: pointer;
}

.preview-scenario-button {
  position: absolute;
  right: 0;
  font-size: 21px;
}

.button2 img {
  margin-right: 16px;
}

.button3 {
  width: 120px;
  height: 53px;
  background-color: #252525;
  font-size: 16px;
  color: white !important;
  font-weight: 300;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  transition: box-shadow 500ms;
  cursor: pointer;
}

.download-button {
  width: 11rem !important;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 17px;
}

.customize-report {
  width: 11rem !important;
}

.report-tt {
  padding-left: 10px;
}

#title-row {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
}
#title-row2 {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
}

#sceanrio-row {
  width: 100%;
  height: 51px;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 2px solid black;
  display: flex;
}

.scenario-tab {
  color: #000;
  font-weight: 600;
  height: 100%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-left: 14px;
  padding-right: 14px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  /* box-shadow: 4.2px -4.2px 10px 0 rgba(0, 0, 0, 0.21); */
  background-color: #cccccc;
  cursor: pointer;
  margin-right: 5px;
}

.scenario-tab-active {
  color: #ebebeb;
  background-color: #363636;
}

.lock {
  overflow-y: hidden;
}

#popup-container {
  position: fixed;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 10;
  display: none;
}
.create-scenario-modal{
  position: fixed;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 10;
  display: flex;
}
.scenario-modal-spacing{
  margin-top: 10%;
}
#report-popup-container {
  position: fixed;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 10;
  display: none;
}
#popup-warning {
  justify-content: center;
  align-items: center;
}

#popup-box {
  padding: 35px;
  padding-top: 50px;
  width: 500px;
  background-color: white;
  display: flex;
  justify-content: center;
  box-shadow: 0px 1px 38px 0 rgba(0, 0, 0, 0.34);
}

#popup-details {
  width: 100%;
}

#create-scenario {
  margin-left: 33px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
#warning-details {
  width: 100%;
}

#create-scenario img {
  margin-right: 10px;
}

#fine-tune-title {
  padding-top: 20px;
  padding-bottom: 12px;
  border-bottom: 2px solid black;
  font-size: 24px;
  font-weight: 600;
  color: black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
#fine-tune-title img {
  margin-right: 18px;
}

#fine-tune-content {
  height: 420px;
  width: 100%;
  border-bottom: 2px solid black;
  overflow-y: hidden;
  display: flex;
}

.menu-item {
  width: 409px;
  height: 58px;
  background-color: whitesmoke;
  padding: 18px;
  font-size: 24px;
  font-weight: 600;
  color: black;
  padding-top: 10px;
  cursor: pointer;
  display: block;
}

#currency {
  display: flex;
  margin-right: 54px;
  cursor: pointer;
  color: #959595;
  font-size: 16px;
  font-weight: 800;
  cursor: pointer;
  align-self: center;
}

#currency-sec {
  display: flex;
  align-self: center;
  margin-right: 54px;
}
#currency-select-sec {
  color: #959595;
  font-size: 14px;
  font-weight: 800;
}
#currency-select {
  color: #959595;
  font-size: 16px;
  font-weight: 800;
  cursor: pointer;
}
#currency-select option {
  color: #959595;
  background-color: #ececec;
}

#preview-box {
  width: 100%;
  height: 420px;
  overflow-y: scroll;
}

#minichartholder {
  width: 1000px;
  margin-top: 90px;
}

.minichart {
  height: 280px;
  margin-bottom: 30px;
}

.testheight {
  height: 280px;
}

.purple {
  background-color: #6d2b61;
}

.red {
  background-color: #e8434f;
}

.yellow {
  background-color: #ffff05;
}

.lightblue {
  background-color: #00bff3;
}

.steeltblue {
  background-color: #556679;
}

.petrollight {
  background-color: #7daebc;
}

.steelbluebright {
  background-color: #777777;
}

.steel {
  background-color: #9ea6b4;
}
.steelBlue {
  background-color: #00677d;
}

.green {
  background-color: #fcc342;
}

.blue {
  background-color: #7761cf;
}

.shiftup {
  margin-top: 30px !important;
}

.smallbar {
  height: 180px;
  width: 280px;
}

.bigbar {
  height: 340px;
  width: 820px;
}
.bigline {
  height: 400px;
  width: 820px;
}

.legend {
  display: flex;
  margin-bottom: 40px;
}

.legend .custom-legend-item {
  margin-left: 20px;
  margin-right: 20px;
}

.legendv {
  margin-top: 46px;
}

.custom-legend-item {
  display: flex;
  font-size: 14.5px;
  color: #363636;
  margin-bottom: 20px;
  align-items: center;
}

.custom-legend-icon {
  width: 18px;
  height: 18px;
  margin-right: 22px;
}

.donutbox {
  -webkit-filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.12));
          filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.12));
}

.chartholder {
  width: 100%;
  display: flex;
  flex-wrap:wrap;
  justify-content: center;
  /* background-color: red; */
  padding-top: 35px;
}
.chartholder2 {
  width: 100%;
  display: flex;
  justify-content: center;
  /* background-color: red; */
}

#visuals {
  padding-top: 67px;
}

.figure-title {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  color: #707070;
}

#fade {
  pointer-events: none;
  position: -webkit-sticky;
  position: sticky;
  z-index: 2;
  right: 0;
  bottom: 2px;
  left: 0;
  height: 100px; /* adjust it to your needs */
  width: auto;
  width: initial;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(235, 235, 235, 1) 80%
  );
}
​ #create-scenario img {
  margin-right: 15px;
}

.pad-50 {
  padding-left: 50px;
  padding-right: 50px;
}

.subtext1 {
  font-size: 14px;
  color: #464646;
  margin-bottom: 40px;
  margin-top: 35px;
}

.subtext2 {
  font-size: 14px;
  color: #464646;
  margin-bottom: 8px;
  margin-top: 8px;
}
sup {
  top: -0.8em;
  font-size: 50%;
}

.deadcenter {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.center {
  display: flex;
  justify-content: center;
}

.report-logo {
  width: 13rem;
}

.t-1 {
  color: #363636;
  font-size: 24px;
  margin-bottom: 62px;
}

.t-2 {
  font-size: 30px;
  font-weight: 600;
  color: #17223c;
}

.t-3 {
  color: #17223c;
  font-size: 24px;
  margin-bottom: 24px;
  font-weight: 600;
}

.t-4 {
  font-size: 30px;
  font-weight: 700;
  color: #252525;
}

.s-1 {
  font-size: 16px;
  color: #363636;
  margin-bottom: 30px;
}

#center-title {
  text-align: center;
  padding-left: 100px;
  margin-top: -5px !important;
}
#center-title2 {
  text-align: center;
  padding-left: 43%;
  margin-top: -5px !important;
}

.s-2 {
  font-size: 16px;
  color: #363636;
  margin-bottom: 14px;
}

.post-img {
  width: 200px;
  height: 200px;
}

.post-img-lg {
  width: 300px;
  height: 300px;
}

.post-small {
  display: flex;
  margin-top: 60px;
}
.post-head {
  display: flex;
  margin-top: 60px;
  margin-bottom: 40px;
}

.post {
  padding-left: 23px;
  padding-right: 23px;
  margin-top: -5px;
  width: calc(100% - 300px);
}
.post-preview {
  padding-left: 23px;
  padding-right: 23px;
  margin-top: -5px;
  width: calc(100% - 200px);
}

.post-preview-text {
  font-size: 16px;
  text-align: justify;
  line-height: 1.2;
}

.post-text {
  font-size: 20px;
  text-align: justify;
  line-height: 1.2;
}

.post-preview-text span {
  cursor: pointer;
}

.nudge {
  margin-left: 5px;
}

.table td,
.table th {
  /* padding: 7.5px; */
  padding-left: 0px;
  vertical-align: middle;
  border: none;
  outline: none;
}

.table thead th {
  vertical-align: middle;
  padding-bottom: 10px;
  border-bottom: 2px solid #363636;
}

.table-signup {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 28px;
  border: 1px solid black !important ;
}

.table-signup td,
.table-signup th {
  border: 1px solid black !important;
  padding: 8px;
}

.table-signup tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table-signup tr:hover {
  background-color: #ddd;
}

.table-signup th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background: #d6cb3f;
  color: black;
}
.margin-adject {
  margin-top: auto;
  margin-bottom: auto;
}
.margin-dec {
  margin-top: 0px !important;
}
.border-sign {
  border-radius: 12px;
  border: 2px solid gray;
  padding: 12px;
}
.row-xp {
  display: flex !important; /* equal height of the children */
}
.col-4-p {
  flex: 1 1 !important; /* additionally, equal width */

  padding: 1em !important;
  border: solid #9b9b9b00;
}
.text-sign {
  font-size: 14px;
}
.font {
  font-size: 12px;
}
.theme-cl {
  color: #665f02;
}
.green-cl {
  color: green;
}

.red-cl {
  color: red;
}

.inputbox {
  width: 100%;
  height: 35px;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  padding: 8px;
  margin-top: 20px;
}
.table-inputbox {
  width: 170px;
  height: 35px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-right: 11px;
  display: flex;
  align-items: center;
  padding: 8px;
}

.searchboxdiv {
  width: 264px;
  height: 35px;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  padding: 8px;
}

.searchboxdiv img {
  margin-left: 12px;
}

.faq-title {
  width: 100%;
  height: 51px;
  background-color: #cccccc;
  font-size: 22px;
  font-weight: 600;
  padding: 8px;
  padding-left: 22px;
  cursor: pointer;
}

#guide-sidebar li {
  list-style: none;
  background-image: url('/assets/expand.png');
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: center;
  background-size: auto auto;
  background-size: initial;
  height: 25px;
  margin-bottom: 5px;
  margin-top: 5px;
}

#guide-sidebar li {
  min-height: -webkit-max-content;
  min-height: -moz-max-content;
  min-height: max-content;
}

#faq-container {
  margin-top: 45px;
}

.faq-body {
  padding: 20px;
}

#guide-container {
  margin-top: 60px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}

#guide-details {
  padding: 48px;
  padding-top: 10px;
  border-left: 2px solid black;
}

.table-inputbox2 {
  width: 140px;
  height: 35px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-right: 11px;
  display: flex;
  align-items: center;
  padding: 8px;
}

.table-inputbox3 {
  height: 34px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-right: 11px;
  align-items: center;
  padding: 8px;
}

.table-inputbox1 {
  width: 210px;
  height: 35px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-right: 11px;
  display: flex;
  align-items: center;
  padding: 8px;
}

.myselect {
  padding: 0px;
  padding-left: 12px;
  padding-right: 38px;
  background-image: url(/assets/arrow-down.png);
  background-repeat: no-repeat;
  background-position-x: 182px;
  background-position-y: 13px;
}

.myselect2 {
  padding: 0px;
  padding-left: 12px;
  padding-right: 38px;
  background-image: url(/assets/arrow-down.png);
  background-repeat: no-repeat;
  background-position-x: 390px;
  background-position-y: 10px;
}
.reportInput {
  padding: 0px;
  padding-left: 12px;
  padding-right: 38px;
}

.f-14 {
  font-size: 14.5px;
}

.normal {
  font-weight: normal !important;
}

#add-more {
  margin-top: 30px;
  display: flex;
  cursor: pointer;
}

#add-more img {
  margin-right: 14px;
}

#build-vol {
  font-size: 16px;
  margin-top: 70px;
  font-weight: 700;
  display: flex;
}

#build-vol > :first-child {
  margin-right: 210px;
}

#btn-row {
  width: 100%;
  margin-top: 34px;
  display: flex;
  justify-content: space-between;
}

.gray {
  background-color: #555555 !important;
}

#btn-row2 {
  width: 100%;
  margin-top: 34px;
  display: flex;
  justify-content: space-between;
}

#sceanrio-btn {
  width: 100%;
  margin-top: 34px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap !important;
}

.break {
  border-bottom: 2px solid rgba(54, 54, 54, 0.3);
}

#mapdiv {
  width: 800px;
  height: 480px;
}
.mt-reset {
  margin-top: unset !important;
}
.vcenter {
  position: relative;
  display: flex;
  height: 35px;
  align-items: center;
  justify-content: space-between;
}

.vcenter1 {
  display: flex;
  height: 35px;
  align-items: center;
}
.vcenter1 p {
  margin-left: 10px;
}

.vcenter2 {
  display: flex;
  height: 34px;
  align-items: center;
}

#machine-type {
  margin-bottom: 48px;
  display: flex;
  height: 35px;
  align-items: center;
  font-weight: bold;
}
#machine-type p {
  margin-right: 66px;
}

.j-s {
  justify-content: start;
}

.no-border {
  border: 0px !important;
}

.center-text {
  text-align: center;
}

.right-text {
  text-align: right;
}

.table td {
  padding-bottom: 14px;
}

.page-container {
  margin-top: 40px;
  margin-bottom: 80px;
  padding-left: 30px;
  padding-right: 30px;
}

.page-container table {
  margin-top: 40px;
}

#report-table tbody {
  border-bottom: 2px solid #363636;
}

#report-head {
  width: 100%;
  font-size: 17.5px;
}

#pagination {
  font-weight: 700;
  margin-top: 10px;
  display: flex;
  justify-content: space-evenly;
  cursor: pointer;
}

.m-l-10 {
  margin-left: 10px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

#pagination p {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

#upload-box {
  width: 100%;
  height: 100%;
  background-color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 10px;
}

#upload {
  font-weight: 600;
  font-size: 16px;
  color: black;
}

#upload-icon {
  display: flex;
  justify-content: center;
  margin-bottom: 45px;
}

select {
  font-weight: bold;
  color: #464646;
  width: 100%;
  background: none;
  outline: none;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -ms-appearance: none; /* get rid of default appearance for IE8, 9 and 10*/
}

.semi-bold {
  font-weight: 600;
}

td img {
  cursor: pointer;
}

.editimg {
  margin-left: 70px;
}

.editimg2 {
  margin-left: 23px;
}

.rp-10 {
  padding-right: 10px;
}

#calc_welcome_box {
  width: 100%;
  /* padding-left: 100px;
  padding-right: 100px; */
  padding-top: 50px;
  margin-bottom: 20px;
}

#info-box {
  margin-top: 100px;
  line-height: 0.89;
}

#info-box p {
  margin-top: 13px;
}
#info-box li {
  margin-top: 13px;
  line-height: 1.4;
}
#info-box ul {
  margin-top: 13px;
}

#btn-row {
  margin-top: 50px;
}

.m-t-30 {
  margin-top: 30px;
}

.z-10 {
  z-index: 10;
}

.p-100 {
  padding-top: 100px !important;
}

.dd-flex {
  display: flex;
  flex-direction: row;
}

.type-btn {
  width: 100%;
  height: auto;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 14px;
  padding-bottom: 14px;
  cursor: pointer;
  transition: box-shadow 500ms;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.445);
}

.type-btn img {
  margin-right: 10px;
}

.type-btn:hover {
  box-shadow: 0px 4px 10px 0 rgba(0, 0, 0, 0.2);
}
.sign-up-text {
  color: #000;
  text-decoration: underline;
  cursor: pointer;
}

.type-selected {
  background-color: #d6cb3f;
  box-shadow: 0px 7px 20px 0 rgba(0, 0, 0, 0.3) !important;
}

#arrow-head-right {
  width: 25px;
  height: 52px;
  position: absolute;
  right: 44px;

  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAA0CAYAAAB/25OLAAAB4klEQVRYhbXXT4gOYRgA8N/uSpFQUg4o5WBKOZiDoszFZrcVZS/sZQ9ESsmBG3HjIKVEHPayXDatbFbfaQ57UOaiNuOg1HJQUkjUZq3DZwvtn2/2m+e5zPQ+z9uvnrfmfabj1Yv9s5jCXrxXMZI0X7Km889zK8axrirSSnT+9b4TY1gVicA+PEBXJAJHcCcagRO4Eo3AJZyJRuAW+qORTgwji0RgJR5jVyQCa/EM2yIR2IQGNkYisF3z87MmEoHdeKR5VmEIHMAQOiIROIYb0QicK4vsQjQC18oiG4xG4F5ZZL3RyAqMlEW2JxKheaOOlUW2IxKBDWiURbY5EoEtGC+LbH0kQnMoeVIW2epIhOZQMlwWWVckQnMouRuNILZdMIpTkcgEBpI0n4lCJnEoSfPvxLTrHXqSNP88t1A38gndSZr/8wtSJ/IDfUmav/4/URfyE/1Jmj+fL1kXcjJJ86cLJetALiZpPrRYQbvIzSTNry9V1A7yEOdbKVwu0sAgZqOQAkcx3eqGqsgb9OJblU1VkA/oxscqQBXkKw7ibVWgVWQah/FyOUAryC8MIF8u0ApyFiPtAEshV3G7XWAx5D4u1wEshIzidF3AfMgEjmMmCplEn+YNV2vMIVPowZe6AfgNhOBjZUhKcb8AAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
}
.flag-next {
  position: relative;
  margin-left: 35px;
  color: white;
  line-height: 50px;
  font-size: 0.76rem;
  text-align: center;
}
.flag-next:before {
  content: '';
  position: absolute;
  top: 0;
  left: -37px;
  width: 0;
  height: 0;
  border-top: 24px solid #363636;
  border-bottom: 28px solid #363636;
  border-left: 36px solid #d6cb3f;
}
#arrow-right {
  width: 25px;
  height: 52px;
  position: relative;
  left: 313px;

  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAA0CAYAAAB/25OLAAAB4klEQVRYhbXXT4gOYRgA8N/uSpFQUg4o5WBKOZiDoszFZrcVZS/sZQ9ESsmBG3HjIKVEHPayXDatbFbfaQ57UOaiNuOg1HJQUkjUZq3DZwvtn2/2m+e5zPQ+z9uvnrfmfabj1Yv9s5jCXrxXMZI0X7Km889zK8axrirSSnT+9b4TY1gVicA+PEBXJAJHcCcagRO4Eo3AJZyJRuAW+qORTgwji0RgJR5jVyQCa/EM2yIR2IQGNkYisF3z87MmEoHdeKR5VmEIHMAQOiIROIYb0QicK4vsQjQC18oiG4xG4F5ZZL3RyAqMlEW2JxKheaOOlUW2IxKBDWiURbY5EoEtGC+LbH0kQnMoeVIW2epIhOZQMlwWWVckQnMouRuNILZdMIpTkcgEBpI0n4lCJnEoSfPvxLTrHXqSNP88t1A38gndSZr/8wtSJ/IDfUmav/4/URfyE/1Jmj+fL1kXcjJJ86cLJetALiZpPrRYQbvIzSTNry9V1A7yEOdbKVwu0sAgZqOQAkcx3eqGqsgb9OJblU1VkA/oxscqQBXkKw7ibVWgVWQah/FyOUAryC8MIF8u0ApyFiPtAEshV3G7XWAx5D4u1wEshIzidF3AfMgEjmMmCplEn+YNV2vMIVPowZe6AfgNhOBjZUhKcb8AAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
}

.disable-btn {
  width: 100%;
  height: auto;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 14px;
  padding-bottom: 14px;
  cursor: not-allowed;
  transition: box-shadow 500ms;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.445);
}
.margin-top-xp-bold {
  margin-top: 30px;
  font-weight: bold;
}
.margin-bottom-xp {
  margin-bottom: 30px;
}

.disable-btn img {
  margin-right: 10px;
}

.disable-btn:hover {
  box-shadow: 0px 4px 10px 0 rgba(0, 0, 0, 0.2);
}

.bar {
  width: 100%;
  height: 52px;
  background-color: #363636;
  margin-top: 10px;
  padding-left: 44px !important;
  padding-right: 69px !important;
  color: white;
}

.progress-holder {
  width: 100%;
  height: 100%;
  line-height: 1;
}

.progress-stage {
  padding-left: 25px !important;
  color: white;
}

.min-width {
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
}

.stage {
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
}
.stage img {
  margin-right: 15px;
}

.hidden {
  display: none !important;
}

/* 
#form-holder::-webkit-scrollbar {
  display: none;
}

#form-holder {
  -ms-overflow-style: none; 
  scrollbar-width: none; 
} */

#form-holder {
  zoom: 0.75;
  -moz-transform: scale(0.75);
  padding: 60px;
}

.calcform {
  width: 840px;
  max-width: 840px;
  padding-top: 40px;
  padding-bottom: 60px;
  overflow-x: scroll;
}

.calcform-wrapper {
  padding-top: 0px !important;
}

.calcform-top-btn {
  margin-top: -3rem;
}

.calcform2 {
  width: 840px;
  max-width: 840px;
  padding-top: 40px;
  padding-bottom: 60px;
}
.button-premium {
  background-color: #d6cb3f;
  color: #252525 !important;
  font-size: 12px;
  cursor: pointer;
  padding: 10px 20px;
}
.arrow-back {
  background-color: #d6cb3f;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAA0CAYAAAB/25OLAAAB10lEQVRYhbXXT2iPcRgA8M82KRJKygGlHByUg9pXcXCxbE2UXdhlByKl5MCNuHGQUiIOu4zL0mSZdnLYwVMuagcHpcZBSSFRy8zhZ4X25/fu9z7P5X37Ps+3Tz3fer/P29bZ2TmLKezFexUjIpasaf/z3IoxrKuKNBPtf73vxChWZSKwDw/QkYnAEdzJRuAErmQjcAlnshG4hb5spB1D2J+JwEo8xq5MBNbiGbZlIrAJ49iYicB2jc/PmkwEduORxlmlIXAAg2jLROAYbmQjcK6UciEbgWullIFsBO6VUnqykRUYLqXsyURo3KijpZQdmQhswHgpZXMmAlswVkpZn4nQGEqelFJWZyI0hpKhUkpHJkJjKLmbjSC3XTCCU5nIBPojYiYLmcShiPhOTrveoTsiPs8t1I18QldE/PMLUifyA70R8fr/RF3IT/RFxIv5knUhJyPi6ULJOpCLETG4WEGryM2IuL5UUSvIQ5xvpnC5yDgGMJuFvMRRTDe7oSryBj34VmVTFeQDuvCxClAF+YqDeFsVaBaZxmG8Wg7QDPIL/Xi+XKAZ5CyGWwGWQq7idqvAYsh9XK4DWAgZwem6gPmQCRzHTBYyiV6NG67WmEOm0I0vdQPwG0R9Y2VHnQ8EAAAAAElFTkSuQmCC);
  background-position: left;
  background-repeat: no-repeat;
  background-size: auto;
  color: #252525 !important;
  font-weight: 600;
}

.arrow-front {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAA0CAYAAAB/25OLAAAB4klEQVRYhbXXT4gOYRgA8N/uSpFQUg4o5WBKOZiDoszFZrcVZS/sZQ9ESsmBG3HjIKVEHPayXDatbFbfaQ57UOaiNuOg1HJQUkjUZq3DZwvtn2/2m+e5zPQ+z9uvnrfmfabj1Yv9s5jCXrxXMZI0X7Km889zK8axrirSSnT+9b4TY1gVicA+PEBXJAJHcCcagRO4Eo3AJZyJRuAW+qORTgwji0RgJR5jVyQCa/EM2yIR2IQGNkYisF3z87MmEoHdeKR5VmEIHMAQOiIROIYb0QicK4vsQjQC18oiG4xG4F5ZZL3RyAqMlEW2JxKheaOOlUW2IxKBDWiURbY5EoEtGC+LbH0kQnMoeVIW2epIhOZQMlwWWVckQnMouRuNILZdMIpTkcgEBpI0n4lCJnEoSfPvxLTrHXqSNP88t1A38gndSZr/8wtSJ/IDfUmav/4/URfyE/1Jmj+fL1kXcjJJ86cLJetALiZpPrRYQbvIzSTNry9V1A7yEOdbKVwu0sAgZqOQAkcx3eqGqsgb9OJblU1VkA/oxscqQBXkKw7ibVWgVWQah/FyOUAryC8MIF8u0ApyFiPtAEshV3G7XWAx5D4u1wEshIzidF3AfMgEjmMmCplEn+YNV2vMIVPowZe6AfgNhOBjZUhKcb8AAAAASUVORK5CYII=);
  background-position: right;
  background-repeat: no-repeat;
  background-size: auto;
}

#contact-holder img {
  margin-top: 20px;
  margin-bottom: 30px;
}

#contact-details {
  padding: 20px;
}

#faq-header {
  display: flex;
  justify-content: space-between;
}

li span {
  font-size: 10px;
}

input {
  background: transparent;
  border: none;
  outline: none;
  width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

.navbar-toggler:focus {
  border: 0px;
  outline: 0px;
}
.toast {
  color: white !important;
}
.errorMessage {
  font-size: 0.8rem;
  color: #E83155;
  width: 300px;
}
.warning-heading {
  font-size: 20px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
}

button {
  border: none !important;
}
.iframe-fluid {
  height: 501px;
  width: 501px;
}
#currency-dropdown {
  display: none;
  position: absolute;
  padding: 5px;
  z-index: 8;
  right: 108px;
  top: 60px;
  background-color: white;
  font-size: 14px;
  color: #707070;
  font-weight: 700;
  text-align: right;
  box-shadow: 0px 8px 24px 0 rgba(0, 0, 0, 0.1);
}

#profile-dropdown {
  display: none;
  z-index: 10;
  padding: 5px;
  background-color: white;
  position: absolute;
  right: 32px;
  top: 60px;
  font-size: 14px;
  color: #707070;
  font-weight: 700;
  text-align: right;
  box-shadow: 0px 8px 24px 0 rgba(0, 0, 0, 0.1);
}

.currency-item {
  padding: 10px;
  cursor: pointer;
}
.currency-item:hover {
  background-color: #ebebeb;
}
.dd-item {
  padding: 10px;
  cursor: pointer;
}
.dd-item:hover {
  background-color: #ebebeb;
}

#account-icon {
  margin-right: 9px;
  cursor: pointer;
}

.field {
  display: flex;
  align-items: center;
}

.field img {
  margin-left: 16px;
}

#banner {
  background-color: #d6cb3f;
  padding: 10px;
  padding-left: 40px;
  padding-right: 40px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#agree-btn {
  width: 140px;
  height: 36px;
  background-color: #363636;
  font-size: 14px;
  color: white !important;
  font-weight: 300;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  transition: box-shadow 500ms;
  cursor: pointer;
}

#agree-btn:hover {
  box-shadow: 0px 4px 8px 0 rgba(0, 0, 0, 0.3);
}

#agree-btn img {
  position: relative;
  left: -20px;
}

.chart-heading {
  cursor: pointer;
}

.overrideable-input{
  font-size: 12px;
}

.customizable-field-tooltip{
  top: 25px;
  right:-50px;
}

.table-responsive{
  width: 100%;
  min-width: 1300px;
}
.overflow-x-scroll{
  overflow-x: hidden;
}
.white_space-nowrap{
  white-space: nowrap;
}

/* MEDIA QUERIES */
@media only screen and (max-width: 2500px) {
  .table-signup {
    font-size: 23px;
  }
}
@media only screen and (max-width: 2400px) {
  .table-signup {
    font-size: 22px;
  }
}

@media only screen and (max-width: 2000px) {
  .table-signup {
    font-size: 20px;
  }
}
@media only screen and (max-width: 1900px) {
  .table-signup {
    font-size: 19px;
  }
  .box-wrapper {
    width: 87%;
  }
}
@media only screen and (max-width: 1700px) {
  .table-signup {
    font-size: 14px;
  }
  .box-wrapper {
    width: 89%;
  }
}
@media only screen and (max-width: 1400px) {
  .overflow-x-scroll{
    overflow-x: scroll;
  }
  .login-container {
    padding: 30px;
  }
  .box-wrapper {
    width: 99%;
  }
}

@media only screen and (max-width: 1199px) {
  #calc_welcome_box {
    padding-left: 10px;
    padding-right: 10px;
  }
  .login-container {
    padding: 10px;
    margin-top: 200px;
  }
  .coverimage {
    background-size: contain;
  }
  .progress-stage {
    zoom: 0.9;
    -moz-transform: scale(0.9);
  }
  .bar {
    padding-left: 10px !important;
    padding-right: 35px !important;
  }
  #arrow-head-right {
    right: 10px;
  }
  #arrow-right {
    left: 205px;
  }
  .dd-flex {
    flex-direction: column;
  }
}

@media only screen and (max-width: 1024px) {
  .table-signup {
    font-size: 14px;
  }
  .early-break {
    width: 100%;
    margin-bottom: 10px;
  }

  #center-title {
    padding-left: 0px;
    margin-top: 0px !important;
  }
  #sceanrio-row {
    margin-top: 50px;
  }
  #title-row2 {
    flex-direction: column;
  }
  .myorder-1 {
    order: 1;
  }
  .myorder-2 {
    order: 2;
  }
  #report-head {
    margin-top: 30px;
  }
  .post-head {
    display: block;
  }
  .post-img-lg {
    width: 100%;
  }
  .post {
    padding: 0px;
    padding-top: 20px;
    width: 100%;
  }
}

@media only screen and (max-width: 992px) {
  .login-container {
    padding: 10px;
    margin-top: 200px;
  }
  .progress-stage {
    zoom: 0.8;
    -moz-transform: scale(0.8);
  }
  .navbar {
    height: auto;
  }

  .menu-item {
    width: 100%;
  }
  #fine-tune-content {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  #form-holder {
    zoom: 0.9;
    -moz-transform: scale(0.9);
  }
  #fine-tune-content {
    display: block;
  }
  .legend {
    display: block !important;
  }

  .pad-50 {
    padding: 0px;
  }

  #currency {
    align-self: initial;
  }

  #profile-dropdown,
  #currency-dropdown {
    position: relative;
    right: 0px;
    top: 0px;
    text-align: left;
    background: none;
    box-shadow: none;
    padding: 0px;
  }
}

@media only screen and (max-width: 768px) {
  .flag-next {
    display: none !important;
  }
  #btn-row2 {
    display: block;
  }

  #sceanrio-btn {
    display: block;
  }

  .background {
    height: 100%;
  }
  .navbar {
    height: auto !important;
  }
  .navbar-brand {
    margin-left: 10px;
  }
  .nav-item {
    padding-left: 10px;
  }
  .navbar-collapse {
    margin-top: 15px;
  }
  .progress-holder {
    flex-direction: column;
  }
  .bar {
    height: auto;
  }
  .progress-stage {
    zoom: 1;
    -moz-transform: scale(1);
    padding: 20px !important;
  }
  .arrow-back {
    background-image: none;
  }
  .min-width {
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
  }

  .bar {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  #arrow-head-right {
    display: none;
  }
  .arrow-front {
    background-image: none;
  }
  .button1 {
    width: 150px;
  }
  .button2 {
    width: 200px;
  }
  #form-content {
    justify-content: unset;
    align-items: unset;
    height: auto;
  }
  #machine-type,
  #btn-row {
    display: block;
  }

  .button1,
  .button2,
  .button3 {
    width: 100%;
    margin-bottom: 10px;
  }
  #title-row {
    flex-direction: column;
  }

  .myorder2-1 {
    order: 1;
  }
  .myorder2-2 {
    order: 2;
  }
  #center-title2 {
    padding-left: 0px;
    margin-top: 0px !important;
  }
  .post-small {
    display: block;
  }
  .post-img {
    width: 100%;
  }
  .post-preview {
    padding: 0px;
    padding-top: 20px;
    width: 100%;
  }

  #faq-header {
    display: block;
  }
  #faq-header p {
    margin-bottom: 20px !important;
  }
  .searchboxdiv {
    width: 100%;
  }

  #guide-container {
    display: block;
  }
  #guide-details {
    padding: 20px;
    border-left: 0px;
    border-top: 2px solid black;
  }
  #banner {
    flex-direction: column;
  }
  #agree-btn {
    margin-top: 10px;
  }
}
.table {
  table-layout: fixed;
}

.guide-item {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.guide-item:hover {
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

#guide-details h3 {
  margin-bottom: 16px;
}

#guide-details p {
  margin-bottom: revert;
  font-size: medium;
}

#guide-details img {
  margin-top: 10px;
  margin-bottom: 30px;
}

.sub-list {
  overflow: hidden;
  max-height: 0px;
  transition: all 0.6s ease-in-out;
}

.expanded {
  max-height: 5000px !important;
  transition: all 0.6s ease-in-out;
}

.scale-icon {
  zoom: 0.7;
}

.arrow-holder {
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.text-small {
  font-size: small;
}

.right-divider {
  border-right: 2px solid black;
}

.custom-modal-dialog {
  width: 100% !important;
}

.custom-modal-dialog .modal-dialog {
  width: 50% !important;
}

.select-machine{
  position: relative;
  bottom: 100px;
  overflow: hidden;
  /* top: -11.8%; */
  transform: translateY(-50%);
}

.text-alignment{
  /* word-break: break-all; */
  word-wrap: break-word;
}

.bg-orange{
  background-color: #ff6600;
}

.bg-yellow{
  background-color: #ffff00;
}

.bg-gray{
  background-color: #bfbfbf;
}

.bg-green{
  background-color: #70ad47;
}

.bg-blue{
  background-color: #4472c4;
}



  @media screen and (max-width: 990px) {
    #map-wrapper {
      position: relative;
      padding: 0 20px 0 20px;
      /* top: -15%; */
      margin-top: 0;
    }
  }

.table-border > td{
  border: 1px solid #000;
  word-wrap: break-word;
  font-size: 12px;
  padding: 5px;
}

.table-border > th{
  border: 1px solid #000;
  word-wrap: break-word;
font-size: 14px;
padding: 5px;

}

.fix-height{
  max-height: 895px;
}

/* fix map heading overlap extra height */

.fill#map {
  display: flex;
  height: auto !important;
  }
  
  
.container-fluid{
  padding-left: 0px;
  padding-right: 0px;
}
.heading-bolder{
  font-weight: bolder;
}
.hr{
  border-bottom: 1px solid black;
}
.upper-hr {
  border-top: 1px solid black;
}
.lower-hr {
  border-bottom: 1px solid black;
}
.subscript-span{
  font-size: 16px;
}
.error-messages {
  font-size: 12px;
  color: red;
  display: none;
}

/* Tooltip container */
.tooltip-p {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip-p .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  font-size: 12px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-p:hover .tooltiptext {
  visibility: visible;
}
.small-loader{
  width: 20%;
}

.fileContainer .uploadPictureContainer {
  width: 60% !important;
}

.label-style {
    text-align: center;
        font-weight: bold;
}
/* CARBON CALCULATOR STEPS */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ccsteps-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.ccsteps-section {
  display: flex;
}
/* Btn-position */
.btn-position {
  position: absolute;
  right: 7%;
  top: 3.7%;
}

.prev-btn{
  position: absolute;
  top: 0;
  left: 5%;
}
#carbon-button{
  margin: 0 10px 0 0; 
}
@media screen and (max-width: 960px){
  .prev-btn{
    margin-top: 60px;

  }
  
}

@media screen and (min-width: 1360px) {
  #save-scenario {
    display: inline-block;
  }
}
@media screen and (max-width: 1360px) {
  #va-btn-container{
    flex-direction:column;
    grid-gap:1rem;
    gap:1rem
  }
  #carbon-button{
    margin:0
  }
}
@media screen and (max-width: 1545px) {
  #save-scenario {
    display: none;
    visibility: hidden;
  }
}

/* PROCESS SECTION */

.accordion {
  width: 640px;
  max-width: 100%;
  margin-top: 2.5rem;
}
.card {
  border-radius: 10px !important;
  margin-bottom: 1rem;
  border: none !important;
}

.card-header {
  padding: 0;
  background-color: #ffffff;
  border-radius: 10px !important;
}
.card-header.active {
  background-color: #156481;
  transition: 1s background-color;
  color: #ffffff;
  border-radius: 10px 10px 0px 0px !important;
}

.arrow-box {
  background-color: #156481;
  width: 36px;
  height: 36px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.arrow-box svg {
  transition: 1s;
}
.arrow-box.active {
  background-color: white;
}
.arrow-box.active svg {
  transform: rotate(180deg);
  transition: 1s;
}
.arrow-box.active svg path {
  stroke: black;
}

.btn-link {
  width: 100%;
  text-align: left;
  padding: 0.75rem 1.25rem;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #99a4b4;
  cursor: pointer;
}
.btn-link:hover {
  color: #99a4b4;
  text-decoration: none;
}
.btn-link:focus {
  box-shadow: none;
}
.active-color {
  color: #ffffff;
}
.btn-link ~ .btn-link {
  width: auto;
}
/* .dropdown {
  margin-bottom: 16px;
}
.dropdown-menu {
  transform: translate3d(5px, 35px, 0px) !important;
  width: 100%;
  margin-top: 0.875rem !important;
}
.active-color {
  color: #ffffff;
}
.btn-primary {
  background: #f2f2f2;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.875rem;
  color: #99a4b4;
  border: none !important;
  box-shadow: none !important;
}
.btn-primary img {
  width: 28px;
}
.dropdown-toggle::after {
  display: none !important;
}
.btn-primary:hover {
  background-color: #f2f2f2 !important;
  color: #99a4b4;
}

.btn-primary:focus {
  background-color: #f2f2f2 !important;
  color: #99a4b4 !important;
} */
.btn-primary:active {
  background-color: #f2f2f2 !important;
  color: #99a4b4 !important;
}
.form-control {
  background: #f2f2f2;
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.875rem;
  color: #99a4b4;
  border: none !important;
  box-shadow: none !important;
  cursor: pointer;
  z-index: 1;
}

.select-option {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-size: 14px;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.input-section img {
  width: 28px;
  z-index: 0;
}

.form-control:hover {
  background-color: #f2f2f2 !important;
  color: #99a4b4;
}
.form-control:active {
  background-color: #f2f2f2 !important;
  color: #99a4b4 !important;
}
.form-control:focus {
  background-color: #f2f2f2 !important;
  color: #99a4b4 !important;
}
.show > .btn-primary.dropdown-toggle {
  background: #f2f2f2 !important;
  color: #99a4b4 !important;
}
.input-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.188rem;
  /* text-transform: capitalize; */
  color: #252525;
  margin-bottom: 8px;
  /* text-transform: capitalize; */
}

.output-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.188rem;
  /* text-transform: capitalize; */
  color: #99a4b4;
  margin-bottom: 8px;
  /* text-transform: capitalize; */
}

.data-field {
  background: #f2f2f2;
  width: 100%;
  min-height: 48px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 1rem;
  margin-bottom: 1rem;
  color: #99a4b4;
  border: none !important;
  box-shadow: none !important;
}
.data-field.lastfield {
  background: rgba(21, 100, 129, 0.2);
}
.input-section {
  background: #f2f2f2;
  position: relative;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 1rem;
  border: none !important;
  box-shadow: none !important;
}
.input-section-scenario {
  position: relative;
  width: 100%;
  border-radius: 10px;
  border: none !important;
  box-shadow: none !important;
}
.user-input {
  background: transparent;
  width: 100%;
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 1rem;
  color: #99a4b4;
  border: none !important;
  box-shadow: none !important;
}
.user-input-scenario {
  background: transparent;
  width: 100%;
  border: none;
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.5rem;
  color: #000000;
  box-shadow: none !important;
}
.user-input-two {
  background: rgba(21, 100, 129, 0.2);

  width: 100%;
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 1rem;
  color: #99a4b4;
  border: none !important;
  box-shadow: none !important;
}
.edit-icon {
  position: absolute;
  top: 20%;
  right: 2%;
  width: 28px;
  z-index: 0;
  pointer-events: none;
  white-space: nowrap;
}
.cross-icon{
  position: absolute;
  top: 25%;
  right: 28%;
  width: 20px !important;
  z-index: 0;
  white-space: nowrap;
}
.edit-icon-scenario {
  position: absolute;
  top: 5%;
  right: 10%;
  width: 28px;
  z-index: 0;
  pointer-events: none;
  white-space: nowrap;
}
.value-unit {
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #99a4b4;
  font-size: 1rem;
  padding-top: 5px;
}
/* SCENARIO SECTION */

.powder-container {
  width: 840px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 2.25rem;
  background-color: #ffffff;
  margin-top: 2.5rem;
  border-radius: 10px;
}

.powder-table-top {
  width: 100%;
  background: #156481;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  margin-bottom: 1.25rem;
}

.powder-table-scenario {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 1.25rem; */
  /* margin-bottom: 1.25rem; */
}

.powder-table-top.total-bottom {
  background: rgba(21, 100, 129, 0.6);
  border-radius: 10px;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  padding-right: 65px;
}

.powder-heading-top {
  width: 100%;
  background: #156481;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1.25rem;
  margin-bottom: 1.25rem;
}

.powder-heading {
  min-width: 117px;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375rem;
  text-transform: capitalize;
  color: #ffffff;
  padding: 0 11px;
}

.divider {
  width: 0;
  height: 50px;
  left: 317px;
  top: 399px;
  border: 1px solid black;
}

.values-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.table-value {
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.188rem;
  text-transform: capitalize;
  color: #99a4b4;
  margin-bottom: 1rem;
}
.table-value-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375rem;
  text-transform: capitalize;
  color: #252525;
  margin-right: 0.75rem;
}
.divTable {
  display: table;
  width: 100%;
  max-width: 350px;
}

.divTableRow {
  display: table-row;
}
.borders-table .divTableCellReport:first-child {
  border-radius: 0 0 0 12px;
}
.borders-table .divTableCellReport:last-child {
  border-radius: 0 0 12px 0;
}
.divTableHeading {
  background-color: #eee;
  display: table-header-group;
}
.divTableCell,
.divTableHead {
  display: table-cell;
  padding: 3px 10px;
}
.divTableHeading {
  background-color: #eee;
  display: table-header-group;
  font-weight: bold;
}
.divTableFoot {
  background-color: #eee;
  display: table-footer-group;
  font-weight: bold;
}
.divTableBody {
  display: table-row-group;
}
.position-top-0 {
  top: -2px !important;
}

.divTable__Cells {
  display: table-cell;
  position: relative;
  top: 5px;

  /* padding: 3px 10px; */
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.188rem;
  /* text-transform: capitalize; */
  color: #000000;
  padding: 2px 10px 14px;
  min-width: 117px;
}

.divTableCell1 {
  display: table-cell;

  /* padding: 3px 10px; */
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.188rem;
  /* text-transform: capitalize; */
  color: #000000;
  padding: 0 10px 14px;
  min-width: 150px;
}

.divTableCell1-special {
  display: table-cell;

  /* padding: 3px 10px; */
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.188rem;
  /* text-transform: capitalize; */
  color: #000000;
  padding: 0 10px 14px;
  min-width: 117px;
}

.divTableCell1-heading {
  display: table-cell;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375rem;
  /* text-transform: capitalize; */
  color: #156481;
  padding: 0 10px 16px;
  min-width: 117px;
}
.responsive-scroll {
  display: block;
  width: calc(100% - 350px);
}

.text-capital {
  text-transform: capitalize;
}
.part-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8.75rem;
  margin-bottom: 3.75rem;
}

.parts-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 2.25rem;
  line-height: 3.063rem;
  /* text-transform: capitalize; */
  color: #252525;
}

.parts-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.day-container {
  width: 270px;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.875rem 0.5rem;
}
.day-container-spacing {
  margin-right: 1.875rem;
}
.day-text {
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.688rem;
  color: #252525;
}
.day-text.color2 {
  color: white;
}
.todayBg1 {
  background: #156481;
}
.todayBg2 {
  background: #1b7395;
}
.todayBg3 {
  background: #1f7fa4;
}
.todayBg4 {
  background: #1e88b0;
}
.todayBg5 {
  background: #218db7;
}
.todayBg6 {
  background: #1792c1;
}
.day-border1 {
  border-radius: 16px 16px 0px 0px;
}
.day-border2 {
  border-radius: 0px 0px 16px 16px;
}

.metal-co2e-powder {
  background: #07beb8;
}
.metal-inert-gass {
  background: #9ceaef;
}

.metal-co2e-energy {
  background: #68d8d6;
}

.metal-Logistics {
  background: #3dccc7;
}

.tomorrowBg1 {
  background: #4ea0b0;
}
.tomorrowBg2 {
  background: #56afc0;
}
.tomorrowBg3 {
  background: #5dc0d3;
}
.tomorrowBg4 {
  background: #5fcce1;
}
.tomorrowBg5 {
  background: #59d4ec;
}
.tomorrowBg6 {
  background: #60e0f9;
}

.color-icon {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  margin-right: 0.5rem;
}
.color-icon.bg-color1 {
  background: #07beb8;
}
.color-icon.bg-color2 {
  background: #68d8d6;
}
.color-icon.bg-color3 {
  background: #3dccc7;
}
.color-icon.bg-color4 {
  background: #9ceaef;
}
.color-icon.bg-color5 {
  background: #5dc0d3;
}
.color-icon.bg-color6 {
  background: #4ea0b0;
}
.color-icon.bg-color7 {
  background: #9ceaef;
}

.color-icon.bg-color8 {
  background: #4ea0b0;
}

.color-icon.bg-color9 {
  background: #56afc0;
}

.color-icon.bg-color10 {
  background: #5dc0d3;
}
.color-icon.bg-color11 {
  background: #9ceaef;
}

.color-icon.bg-production-header {
  background: #f4ea56;
  border: 1px solid #000000;
}

.color-icon.bg-unpackingTime {
  background: #ffa600e5;
}

.color-icon.bg-setupTime {
  background: #e77841;
}

.color-icon.bg-buildTime {
  background: #00677d;
}

.color-icon.bg-coolDownTime {
  background: #7daebc;
}

.color-rep-value {
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.188rem;
  /* text-transform: capitalize; */
  color: #99a4b4;
  margin-right: 2.5rem;
}

.total-cost-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1.875rem;
}
.total-cost-number {
  font-style: normal;
  font-weight: 600;
  font-size: 1.875rem;
  line-height: 2.5rem;
  text-transform: capitalize;
  color: #252525;
}

.graph-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 8.75rem;
}

.graph-section svg {
  overflow: visible;
}

.graph-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 2.25rem;
  line-height: 3.063rem;
  /* text-transform: capitalize; */
  color: #252525;
  padding-bottom: 1.75rem;
}

/* REPORT SECTION */

.reports-section {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 2.5rem;
}

.reports-detail-section {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.875rem 3.75rem;
}


.report-detail-inner-spacing {
  padding-top: 1.875rem;
}
.report-detail-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* REPORTS TWO */

.reports-section {
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;
}

.reports-buttons-section {
  display: flex;
  justify-content: center;
  padding: 11px;
  width: 92.3%;
  background: #156481;
  border-radius: 10px 10px 0px 0px;
}
.bg-transparent {
  background: transparent !important;
}
.report-button-one {
  align-items: center;
  display: inline-flex;
  font-family: inherit;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  line-height: 1;
  opacity: 1;
  outline: 0px;
  transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
  height: 43px;
  padding: 12px 17px;
  background: #156481;
  box-shadow: none;
  border: none;
  color: rgb(255, 255, 255);
  cursor: pointer;
  border-radius: 10px;
  margin: 0rem 1.25rem 0rem 0rem;
  white-space: nowrap;
}
.report-button-one.report-button-two {
  background: #1b7395;
}
.report-button-one.report-button-three {
  background: #1f7fa4;
}
.report-button-one.report-button-four {
  background: #1c88b3;
}
.report-button-one.report-button-five {
  background: #1890be;
}
.report-button-one.report-button-six {
  background: #0190c7;
}
.report-button-one.report-button-seven {
  background: #0095ce;
}
.report-button-one.report-button-eight {
  background: #019eda;
}
.divTableReport {
  display: table;
  width: 100%;
  background-color: white;
}
.divTableRowTop {
  display: table-row;
  background: #156481;
  border-radius: 10px 10px 0px 0px;
}
.divTableCellReport {
  display: table-cell;
  width: 112px;
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #000000;
  padding: 2.625rem 0.688rem;
  border: 1px solid #99a4b4;
}
.divTableCellReportStartHeading {
  display: table-cell;
  width: 112px;
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.625rem;
  text-transform: capitalize;
  color: #252525;
  padding: 2.625rem 0.688rem;
  border: 1px solid #99a4b4;
  vertical-align: middle;
  text-align: center;
}
.divTableCellReportTop {
  display: table-cell;
  width: 112px;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  padding: 0.688rem;
  vertical-align: middle;
}
/* .report-two-top{
  background: #156481;
border-radius: 10px 10px 0px 0px;
} */
.report-two-text2 {
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375rem;
  /* identical to box height */

  text-transform: capitalize;

  /* White color */

  color: #ffffff;
}
.hotspotBg {
  background-color: #82D1E6;
}
.borderForTable {
  border-radius: 10px;
  overflow: hidden;
}
.borderBottomForTableone {
  border-radius: 0 0 0 12px;
}
.borderBottomForTabletwo {
  border-radius: 0 0 12px 0;
}
.textAlignLeft {
  text-align: left !important;
}
.report-two-text {
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.188rem;
  /* MAin Black */

  color: #252525;
}
.warning_note{
  font-size: 12px;
}

@media screen and (max-width: 650px) {
  .ccsteps-section {
    flex-direction: column;
  }
  .parts-container {
    flex-direction: column;
  }
  .responsive-scroll {
    overflow-x: auto;
  }
  .day-reponsive-spacing {
    margin-right: 0;
    margin-bottom: 2rem;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .reports-buttons-section {
    flex-direction: column;
  }
  .report-button-one {
    margin: 0 0 1.25rem 0;
  }
}
/* MATERIAL TABLE VALUES SECTION */

.dropdown-input {
  width: 100%;
  padding: 0;
  -webkit-appearance: menulist;
}

.report-border-one {
  border-collapse: collapse;
  border-radius: 10px 0 0 0;
}
.report-border-two {
  border-collapse: collapse;
  border-radius: 0 10px 0 0;
}
.weekday {
  padding: 1rem 0;
  position: relative;
}

/* .weekday:before{
  content:"";
  width: 33.33%;
  height: 150px;
  background-color: beige;
  position: absolute;
  left: 37.5%;
  top: 50%;
  transform: translateY(-50%);
/* } */

.singleDaySchedule {
  display: flex;
  justify-content: flex-start;
  width: auto;
  min-width: 154.88px;
  height: 80px;
  position: relative;
}

.singleDayGraph {
  height: 100%;
  padding: 8px;
}

.bg-intro {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.bg-wrap {
  height: 82vh;
  padding: 0 60px;
  width: 100%;
}

.bg-intro img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.calc_welcome_wrap .t-2 {
  color: #fff;
}

.bg-wrap #info-box p {
  color: #fff;
}

.bg-wrap .green-businees-text {
  color: #b7b7b7;
}

.bg-wrap .page-container {
  position: absolute;
  bottom: 40px;
  margin: 0;
  width: calc(100% - 120px);
}

.bg-wrap .welcome-heading {
  color: #fff;
  text-align: center;
}

.page-container .btn-contain {
  position: relative;
  padding-top: 33.33%;
  overflow: hidden;
  border-radius: 8px;
}

.page-container .btn-contain img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.page-container .btn-contain ~ .btn-container {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
}

.modal-flex .selection-button,
.page-container .btn-contain ~ .btn-container button {
  background: linear-gradient(90deg, #0095c8 0%, #3abfec 103.09%);
}

.modal-flex .selection-button {
  width: 100%;
  min-width: 300px;
}

.modal-flex#btn-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.calc_welcome_main {
  position: relative;
  top: 7%;
  transform: translateY(-50%);
}

.calc_welcome_main #info-box {
  margin-top: 0;
}

.powder-details {
  min-width: 1082px;
  margin: auto;
}

.ps-day-container {
  min-width: 152.88px;
}
.img-tooltip {
  height: 28px;
  z-index: 10;
  pointer-events: all;
}

.img-tooltip img {
  top: 0;
}

.dropdown-item:active {
  background-color: #00677d;
}

.graph-section.width--mod{
  width: 100%;
  max-width: 450px;
}

.report-two-text a{
  color: #007bff !important;
    text-decoration: none !important;
    background-color: transparent;
}
.ps-dropdown{
  right: 0;
  width: 10%;
}


.powder-heading-top.head-padding{
  padding-left: 10px;
  padding-right: 10px;
}

.metal-scenario-tc{
  padding: 0 0.4rem;
}
.metal-scenario-tf{
  padding: 0 2.4rem;
}
.metal-report-tc{
  padding: 0 5rem 0 0;
}
.metal-report-tf{
  padding: 0 0 0 1.2rem;
}

.text-align-end{
  text-align: end;
}

.click-here{
  color: blue !important;
  font-size: small;
}

.primary-color{
  color: #00677d;
}

.black-color{
  color: #000000;
}
.bg-production-scenario{
  background-color:#eeeeed;
}

.light-dark{
  background-color: #918b8b;
  
}

.powder-table-top.p-55{
  padding-right: 50px !important;
}

.btn-tooptip{
  position: absolute;
   top: 17px;
  margin-right: 35%; 
}


.heading {
  color: black;
  font-size: large;
}
.welcome-heading{
  font-style: normal;
font-weight: 700;
font-size: 1rem;
line-height: 1rem;
color: #252525;
padding-bottom: 1rem;
}
.green-businees-text{
  font-style: normal;
font-weight: 700;
font-size: 1rem;
line-height: 1rem;
color: green;
padding-bottom: 1rem;
}

.btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn__outline {
  align-items: center;
  display: inline-flex;
  font-family: inherit;
  font-size: 18px;
  font-weight: 600;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 25px;
  transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
  height: 60px;
  padding: 20px 65px;
  color: #156481;
  cursor: pointer;
  border: 1px solid #156481;
  outline: auto;
  border-radius: 10px;
  max-width: 15rem;
  margin: 0rem 0rem 0rem 1.25rem;
  white-space: nowrap;
}

.btn__outline :active{
  border: 1px solid #156481;
}

.selection-button {
  align-items: center;
  display: inline-flex;
  font-family: inherit;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 1;
  opacity: 1;
  outline: 0px;
  transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
  height: 60px;
  padding: 20px 65px;
  background-image: url("/assets/btn-background.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  box-shadow: none;
  border: none;
  color: rgb(255, 255, 255);
  cursor: pointer;
  border-radius: 10px;
  max-width: 15rem;
  margin: 0rem 0rem 0rem 1.25rem;
  white-space: nowrap;
}
.step-button {
  margin-top: 2.5rem;
}

.selection-button.unselected-btn{
background-color: white;
background-image: none;
color: #99A4B4;

}

@media screen and (max-width: 720px) {
  .btn-container {
    flex-direction: column;
    
  }
  .selection-button{
    margin: 20px 0 0 0;
  }
  
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
