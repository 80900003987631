.container-fluid{
  padding-left: 0px;
  padding-right: 0px;
}
.heading-bolder{
  font-weight: bolder;
}
.hr{
  border-bottom: 1px solid black;
}
.upper-hr {
  border-top: 1px solid black;
}
.lower-hr {
  border-bottom: 1px solid black;
}
.subscript-span{
  font-size: 16px;
}
.error-messages {
  font-size: 12px;
  color: red;
  display: none;
}

/* Tooltip container */
.tooltip-p {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip-p .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  font-size: 12px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-p:hover .tooltiptext {
  visibility: visible;
}
.small-loader{
  width: 20%;
}

.fileContainer .uploadPictureContainer {
  width: 60% !important;
}

.label-style {
    text-align: center;
        font-weight: bold;
}