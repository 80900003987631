.heading {
  color: black;
  font-size: large;
}
.welcome-heading{
  font-style: normal;
font-weight: 700;
font-size: 1rem;
line-height: 1rem;
color: #252525;
padding-bottom: 1rem;
}
.green-businees-text{
  font-style: normal;
font-weight: 700;
font-size: 1rem;
line-height: 1rem;
color: green;
padding-bottom: 1rem;
}

.btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn__outline {
  align-items: center;
  display: inline-flex;
  font-family: inherit;
  font-size: 18px;
  font-weight: 600;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 25px;
  transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
  height: 60px;
  padding: 20px 65px;
  color: #156481;
  cursor: pointer;
  border: 1px solid #156481;
  outline: auto;
  border-radius: 10px;
  max-width: 15rem;
  margin: 0rem 0rem 0rem 1.25rem;
  white-space: nowrap;
}

.btn__outline :active{
  border: 1px solid #156481;
}

.selection-button {
  align-items: center;
  display: inline-flex;
  font-family: inherit;
  font-size: 16px;
  font-weight: 600;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 1;
  opacity: 1;
  outline: 0px;
  transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
  height: 60px;
  padding: 20px 65px;
  background-image: url("/assets/btn-background.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  box-shadow: none;
  border: none;
  color: rgb(255, 255, 255);
  cursor: pointer;
  border-radius: 10px;
  max-width: 15rem;
  margin: 0rem 0rem 0rem 1.25rem;
  white-space: nowrap;
}
.step-button {
  margin-top: 2.5rem;
}

.selection-button.unselected-btn{
background-color: white;
background-image: none;
color: #99A4B4;

}

@media screen and (max-width: 720px) {
  .btn-container {
    flex-direction: column;
    
  }
  .selection-button{
    margin: 20px 0 0 0;
  }
  
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}